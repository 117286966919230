<template>
    <h1>On direz que vous êtes perdu ?</h1>
</template>

<script>
export default {
  name: "NotFound",
  created(){
    this.$store.state.authorize = false
  }
};
</script>

<style scoped>
</style>