<template>
  <not-found></not-found>
</template>

<script>
import NotFound from './NotFound.vue'
export default {
    components:{
        NotFound
    }

}
</script>

<style>

</style>